import Page from "../../components/Page";
import solutions from "../../assets/json/solutions.json";
import cloudSolutions from "../../assets/images/cloud-solutions.jpg";
import { Helmet } from "react-helmet";

const CloudSolutions = () => {
  return (
    <>
      <Helmet>
        <title>Cloud Solutions — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={solutions.cloudSolutions.heading}
        body={solutions.cloudSolutions.body}
        img={cloudSolutions}
      />
    </>
  );
};

export default CloudSolutions;
