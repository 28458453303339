import Page from "../../components/Page";
import solutions from "../../assets/json/solutions.json";
import solutionsImg from "../../assets/images/solutions.jpg";
import { Helmet } from "react-helmet";

const Solutions = () => {
  return (
    <>
      <Helmet>
        <title>Solutions — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={solutions.solutions.heading}
        body={solutions.solutions.body}
        img={solutionsImg}
      />
    </>
  );
};

export default Solutions;
