import Page from "../../components/Page";
import solutions from "../../assets/json/solutions.json";
import securityAudit from "../../assets/images/security-audit.jpg";
import { Helmet } from "react-helmet";

const SecurityAudit = () => {
  return (
    <>
      <Helmet>
        <title>Security Audit — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={solutions.securityAudit.heading}
        body={solutions.securityAudit.body}
        img={securityAudit}
      />
    </>
  );
};

export default SecurityAudit;
