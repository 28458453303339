import React, { useState, useRef } from "react";
import contactUs from "../assets/images/contactUs2.jpg";
import InputBox from "../components/InputBox";
import Button from "../components/Button";
import FlipRightAnimation from "../components/AnimatedTitle";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import Footer from "../components/Footer";

const ContactPage = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    companyName: "",
    name: "",
    email: "",
    message: "",
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInputChange = (e) => {
  
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {

    const { companyName, name, email, message } = formData;


    if (!companyName.trim()) {
      toast.error("Company Name cannot be blank");
      return false;
    }
    if (!name.trim()) {
      toast.error("Name cannot be blank");
      return false;
    }
    if (!email.trim()) {
      toast.error("Email cannot be blank");
      return false;
    }
    if (!validateEmail(email)) {
      toast.error("Invalid email address");
      return false;
    }
    if (!message.trim()) {
      toast.error("Message cannot be blank");
      return false;
    }
    return true;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    emailjs
      .sendForm("service_ic3tuxr", "template_qggrl0a", form.current, {
        publicKey: "FFs5eIyqO7nAwROLS",
      })
      .then(
        () => {
          toast.success("Message sent successfully! We will contact you soon.");
          setFormData({ companyName: "", name: "", email: "", message: "" });
        },
        (error) => {
          toast.error("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Contact Us — Zion</title>
        <meta name="description" content="Contact us for inquiries and support." />
      </Helmet>
      <ToastContainer />
      <div className="d-flex flex-column align-items-center pb-4">
        <FlipRightAnimation text="Contact Us" className="mb-5" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 card p-5 shadow-lg">
              <h2 className="mb-5">Get in Touch</h2>
              <form ref={form} onSubmit={sendEmail}>
                <InputBox
                  label="Company's Name"
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Enter your company's name"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  required={true}
                />
                <InputBox
                  label="Name"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required={true}
                />
                <InputBox
                  label="Email"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required={true}
                />

                <label htmlFor="message" className="form-label">
                  Message <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Enter your message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                ></textarea>
                <Button
                  type="submit"
                  classes="btn-primary w-100 my-4"
                  text="Send"
                  onClick={sendEmail}
                />
              </form>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <img src={contactUs} alt="Contact Us" width="100%" />
            </div>
            <div className="row g-4 align-items-center my-5">
              <FlipRightAnimation
                text="Frequently Asked Questions"
                className="mb-4"
              />

              <div className="col-12">
                <div className="accordion accordion-flush" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button fw-semibold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What does the Company do?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        We provide comprehensive IT infrastructure solutions and
                        managed IT services to help businesses optimize their
                        technology and operations.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button fw-semibold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        What are IT infrastructure solutions?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        IT infrastructure solutions involve designing,
                        implementing, and managing the essential technology
                        components that support an organization's operations. This
                        includes servers, storage, networking equipment, and more.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button fw-semibold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What are managed IT services?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Managed IT services involve outsourcing the management of
                        your IT systems and processes to our team of experts. This
                        includes monitoring, maintenance, support, and strategic
                        planning to ensure your IT environment runs smoothly and
                        efficiently.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button fw-semibold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How do you deliver your services?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        We deliver our services through a combination of on-site
                        visits and remote support. Our team assesses your specific
                        needs, designs tailored solutions, and provides continuous
                        management and support to ensure optimal performance.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button fw-semibold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        What industries do you serve?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        IT is an integral part of any business, from small
                        businesses to enterprises. We cater to all, allowing them
                        to focus on their core operations while we take on the
                        responsibility of deploying IT solutions and ensuring
                        their maintenance and upkeep.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default ContactPage;
