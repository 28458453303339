import Page from "../../components/Page";
import services from "../../assets/json/services.json";
import remoteInfrastructureManagement from "../../assets/images/remote-infrastructure-management.jpg";
import { Helmet } from "react-helmet";

const RemoteInfrastructureManagement = () => {
  return (
    <>
    <Helmet>
        <title>Remote Infrastructure Management — Zion</title>
        <meta name="description" content="" />
      </Helmet>
    <Page
      heading={services.remoteInfrastructureManagement.heading}
      body={services.remoteInfrastructureManagement.body}
      img={remoteInfrastructureManagement}
    />
    </>
  );
};

export default RemoteInfrastructureManagement