import AboutCard from "../components/AboutCard";
import AboutClock from "../assets/images/aboutClock.jpg";
import WhatsappImage from "../assets/images/WhatsAppImage.jpg";
import AboutMission from "../assets/images/aboutMisson.png";
import { useNavigate } from "react-router-dom";
import "../assets/css/AboutsUs.css";
import ceo from "../assets/images/ceomain.png";
import FlipRightAnimation from "../components/AnimatedTitle";
import Button from "../components/Button";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  const navigate = useNavigate();
  const aboutCards = [
    {
      Image: AboutClock,
      title: "Our Vision",
      subTitle:
        "To be the leading provider of cutting-edge IT infrastructure and managed services, transforming businesses through exceptional technology solutions, unmatched expertise, and unwavering dedication to customer success.",
    },
    {
      Image: AboutMission,
      title: "Our Mission",
      subTitle:
        "To empower businesses by delivering innovative, reliable, and scalable IT infrastructure and managed services, fostering long-term partnerships and driving success through our commitment to excellence, integrity, and customer satisfaction.",
    },
    {
      Image: WhatsappImage,
      title: "Our Values",
      subTitle: (
        <>
          <ul>
            <li>
              Customer Focus Focused on solving customer
              challenges and providing sustainable business and technology
              advantages.
            </li>
            <li>
              Passion and Commitment Developing scalable,
              future-proof business solutions that help customers move ahead.
            </li>
            <li>
              Transparency and Accountability Building a
              seamless channel for information exchange between customers and us
              and accountable to deliverables and promises.
            </li>
            <li>
              Integrity Believing in doing the right things in
              the best interest of our customers, thereby winning the trust of
              all the key stakeholders.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>About Us — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="container-fluid">
        <div className="row d-flex flex-column justify-content-center align-items-center about-us-hero">
          <div className="col-lg-6 col-11 d-flex flex-column  text-center">
            <h1 className="text-white mb-4" style={{wordSpacing:"1rem"}}>Get to know Insight</h1>
            <p className="text-white fs-6 text-center">
              Who we are and what we do goes far beyond providing technology
              solutions. Learn how we’re making a difference in the world around
              us.{" "}
            </p>
            <p>
              {/* <a
                className="btn btn-primary px-3 py-2"
                onClick={() => navigate("/contact")}
              >
                Talk to a Specialist
              </a> */}
              <Button
                onClick={() => navigate("/contact")}
                classes="btn-primary my-4"
                text="Talk to a Specialist"
              />
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="text-center my-4">
          {/* <h1 className="pt-1 h1 fw-medium">About Us</h1> */}
          <FlipRightAnimation text="About Us" className="mb-5" />
        </div>
        <div className="row">
          <div className="col-12 col-md-12 mx-auto">
            <p className="text-left">
              By leveraging our strategic design, engineering expertise,
              industry capabilities, and comprehensive deployment and support
              services, we empower our clients to navigate the digital landscape
              and accelerate their growth. With a team of over 200
              professionals, a client base exceeding 200 active clients, and a
              state-of-the-art 24×7 operations center in New Delhi, we ensure
              seamless management of technology, talent, and tools to enhance
              the end-user experience and maintain continuous digital
              infrastructure availability. We prioritize business-centric
              solutions, enabling our clients to innovate and succeed today
              while paving the way for a prosperous future.
            </p>
          </div>
        </div>
      </div>

      <div className="container my-4">
        <div className="row">
          {aboutCards.map((card, index) => (
            <div className="col-12 mb-4" key={index}>
              <AboutCard
                Image={card.Image}
                title={card.title}
                subTitle={card.subTitle}
                reverse={index % 2 !== 0}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center about-us-insight">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-6 mb-4 ceo-img">
                <figure>
                  <img
                    alt="Zion CEO"
                    src={ceo}
                    className="zionceo"
                  
                  />
                </figure>
              </div>

              <div className="col-md-6 text-white fs-6 d-flex flex-column gap-2">
                <div className="fs-4">
                  "At Zion, our focus is on cultivating impactful partnerships that transform challenges into opportunities for our clients, partners, and team."
                </div>
                <div>
                  <strong>Shaji Jacob</strong>
                  <br />
                  <span className="fs-6">
                  Founder and Chief Executive Officer, Zion
                  </span>
                </div>
              </div>
            </div>
          </div>

          <p className="text-center mb-0 mt-4 mt-md-0">
            {/* <a
              className="btn btn-primary px-3 py-2"
              onClick={() => navigate("/contact")}
            >
              Talk to a Specialist
            </a> */}
            <Button
              onClick={() => navigate("/contact")}
              classes="btn-primary"
              text="Talk to a Specialist"
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
