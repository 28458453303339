import Page from "../../components/Page";
import solutions from "../../assets/json/solutions.json";
import digitalWorkspace from "../../assets/images/digital-workspace.jpg";
import { Helmet } from "react-helmet";

const DigitalWorkspace = () => {
  return (
    <>
      <Helmet>
        <title>Digital Workspace — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={solutions.digitalWorkspace.heading}
        body={solutions.digitalWorkspace.body}
        img={digitalWorkspace}
      />
    </>
  );
};

export default DigitalWorkspace;
