import Page from "../../components/Page";
import services from "../../assets/json/services.json";
import itServicesForSmallBusiness from "../../assets/images/it-services-for-small-business.jpg";
import { Helmet } from "react-helmet";

const ItServicesForSmallBusiness = () => {
  return (
    <>
    <Helmet>
        <title>IT Services for Small Business — Zion</title>
        <meta name="description" content="" />
      </Helmet>
    <Page
      heading={services.itServiesForSmallBusinesses.heading}
      body={services.itServiesForSmallBusinesses.body}
      img={itServicesForSmallBusiness}
    />
    </>
  );
};

export default ItServicesForSmallBusiness