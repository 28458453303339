import Page from "../../components/Page";
import services from "../../assets/json/services.json";
import servicesImg from "../../assets/images/services.jpg";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={services.services.heading}
        body={services.services.body}
        img={servicesImg}
      />
    </>
  );
};

export default Services;
