import Page from "../../components/Page";
import solutions from "../../assets/json/solutions.json";
import iot from "../../assets/images/iot.jpg";
import { Helmet } from "react-helmet";

const InternetOfThings = () => {
  return (
    <>
      <Helmet>
        <title>Internet of Things — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={solutions.internetOfThings.heading}
        body={solutions.internetOfThings.body}
        img={iot}
      />
    </>
  );
};

export default InternetOfThings;
