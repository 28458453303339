import Page from "../../components/Page";
import services from "../../assets/json/services.json";
import managedServices from "../../assets/images/managed-services.png";
import { Helmet } from "react-helmet";

const ManagedServices = () => {
  return (
    <>
      <Helmet>
        <title>Managed Services — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={services.managedServices.heading}
        body={services.managedServices.body}
        img={managedServices}
      />
    </>
  );
};

export default ManagedServices;
