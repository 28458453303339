import Page from "../../components/Page";
import solutions from "../../assets/json/solutions.json";
import dataCentre from "../../assets/images/data-center.jpg";
import { Helmet } from "react-helmet";

const DataCentre = () => {
  return (
    <>
      <Helmet>
        <title>Data Centre — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={solutions.dataCentre.heading}
        body={solutions.dataCentre.body}
        img={dataCentre}
      />
    </>
  );
};

export default DataCentre;
