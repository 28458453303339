import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import "../assets/css/Page.css";

const Page = ({ heading, body, img }) => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const contentRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        setHeight(contentRef.current.offsetHeight);
      }
    };

    // Initialize ResizeObserver to track content size
    const resizeObserver = new ResizeObserver(handleResize);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    // Clean up observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Only run once on mount

  useEffect(() => {
    // Set image height when content height changes
    if (imageRef.current) {
      imageRef.current.style.height = `${height}px`;
    }
  }, [height]); // Runs when `height` changes

  return (
    <>
      <h1
        className="d-flex justify-content-center text-center align-items-center display-4 fw-semibold pb-2 fw-normal page-title"
        style={{ backgroundColor: "rgb(177, 60, 120)", color: "#ffffff" }}
      >
        {heading}
      </h1>
      <div className="container mb-5">
        <div className="row m-0 mb-5 g-5">
          <div className="col-lg-6">
            <img
              ref={imageRef}
              src={img}
              alt={heading}
              className="d-lg-block d-none"
              style={{ width: "100%" }}
            />
            <img
              src={img}
              alt={heading}
              className="d-lg-none d-block"
              style={{ width: "100%" }}
            />
          </div>
          <div
            ref={contentRef}
            className="col-lg-6 fs-6"
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => navigate("/contact")}
            classes="btn-primary my-4"
            text="Let's Connect"
          />
        </div>
      </div>
    </>
  );
};

export default Page;
