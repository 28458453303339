import Page from "../../components/Page";
import services from "../../assets/json/services.json";
import dataCentreServices from "../../assets/images/data-centre-services.jpg";
import { Helmet } from "react-helmet";

const DataCentreServices = () => {
  return (
    <>
      <Helmet>
        <title>Data Centre Services — Zion</title>
        <meta name="description" content="" />
      </Helmet>
      <Page
        heading={services.dataCentreServices.heading}
        body={services.dataCentreServices.body}
        img={dataCentreServices}
      />
    </>
  );
};

export default DataCentreServices;
