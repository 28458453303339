import React from "react";
import ExpertiseCard from "../components/ExpertiseCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Text.css";
import AboutSection from "../components/AboutSection";
import FlipRightAnimation from "../components/AnimatedTitle";
import ClientCarousel from "../components/ClientCarousel";
import "../assets/css/Swiper.css";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const cardData = [
    {
      ImageUrl:
        "https://ziongroup.com/wp-content/uploads/2024/06/infrastructure-1.png",
      cardTitle: "Infrastructure Solutions",
      cardDescription:
        "Our infrastructure solutions enhance efficiency, security, and seamless integration.",
    },
    {
      ImageUrl:
        "https://ziongroup.com/wp-content/uploads/2024/06/security-2.png",
      cardTitle: "Security Solutions",
      cardDescription:
        "Our security solutions ensure data protection, threat mitigation, and compliance.",
    },
    {
      ImageUrl:
        "https://ziongroup.com/wp-content/uploads/2024/06/database.png",
      cardTitle: "Cloud Solutions",
      cardDescription:
        "Our cloud solutions deliver scalability, flexibility, enhanced security, and cost efficiency for businesses of all sizes.",
    },
    {
      ImageUrl:
        "https://ziongroup.com/wp-content/uploads/2024/06/database.png",
      cardTitle: "Managed Services",
      cardDescription:
        "Our managed services offer proactive support, reduced downtime, and cost savings.",
    },
    {
      ImageUrl:
        "https://ziongroup.com/wp-content/uploads/2024/06/infrastructure-1.png",
      cardTitle: "Multi-Brand AMC/FMS Services",
      cardDescription:
        "Our Multi-Brand AMC/FMC services ensure optimal performance, maintenance, and longevity..",
    },
    {
      ImageUrl:
        "https://ziongroup.com/wp-content/uploads/2024/06/security-2.png",
      cardTitle: "Remote Infrastructure Management",
      cardDescription:
        "Our Remote Infrastructure Management ensures continuous monitoring, enhanced performance, and cost-effective operations.",
    },
  ];

  // const testimonials = [
  //   {
  //     id: 1,
  //     name: "Dr Vivek Rastogi",
  //     title: "CTO, Fabindia",
  //     image:
  //       "https://ziongroup.com/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-04-at-22.01.03_ddb07b09.jpg",
  //     text: "Partnering with Zion Computers has been a game-changer for our business. Our relationship goes back to 1994, and their expertise in IT infrastructure and managed services has ensured seamless operations and robust support as we've grown. Their commitment to excellence and customer satisfaction truly sets them apart.",
  //   },
  //   {
  //     id: 2,
  //     name: "Dr Vivek Rastogi",
  //     title: "CTO, Fabindia",
  //     image:
  //       "https://ziongroup.com/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-04-at-22.01.03_ddb07b09.jpg",
  //     text: "Partnering with Zion Computers has been a game-changer for our business. Our relationship goes back to 1994, and their expertise in IT infrastructure and managed services has ensured seamless operations and robust support as we've grown. Their commitment to excellence and customer satisfaction truly sets them apart.",
  //   },
  //   {
  //     id: 3,
  //     name: "Dr Vivek Rastogi",
  //     title: "CTO, Fabindia",
  //     image:
  //       "https://ziongroup.com/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-04-at-22.01.03_ddb07b09.jpg",
  //     text: "Partnering with Zion Computers has been a game-changer for our business. Our relationship goes back to 1994, and their expertise in IT infrastructure and managed services has ensured seamless operations and robust support as we've grown. Their commitment to excellence and customer satisfaction truly sets them apart.",
  //   },
  // ];

  // const settings = {
  //   dots: true,
  //   infinite: true, // Infinite scrolling
  //   speed: 500,
  //   slidesToShow: 3, // Always show 3 slides
  //   slidesToScroll: 1,
  //   centerMode: true, // Center the middle slide
  //   centerPadding: "0px", // No padding around the center card
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: "0px",
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1, // On mobile, only 1 slide
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: "0px",
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <Helmet>
        <title>Zion — IT Solutions and Services</title>
        <meta
          name="description"
          content=""
        />
      </Helmet>
      <AboutSection />

      {/* Our  Expertise Section*/}

      <div className="theme-color pt-1">
        <div className="container">
          <div className="row mt-4 pb-4 ">
            <div className="pb-4 mb-4">
              <FlipRightAnimation text={"Our Expertise"} />
            </div>
            {cardData.map((card, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 col-md-4 mb-4 d-flex align-items-stretch"
              >
                <ExpertiseCard {...card} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Testimonial Section */}
      <div className="container-fluid mb-5">
        <div className="row">
          <FlipRightAnimation text={"Testimonial"} />
          <ClientCarousel />
        </div>
      </div>
    </>
  );
};

export default HomePage;
